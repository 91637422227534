<template>
	<div class="mt-20">
		<div class="table-block" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<table>
				<thead>
					<tr>
						<th>№</th>
						<th style="max-width: 100px">{{ $t("contractNo") }}</th>
						<th style="max-width: 250px">{{ $t("contractDate") }}</th>
						<th>{{ $t("name") }}</th>
						<th>{{ $t("startingPrice") }}</th>
						<th style="text-align: center">{{ $t("contractAmount") }}</th>
						<th style="text-align: center">{{ $t("customer") }}</th>
						<th style="text-align: center">{{ $t("customerInn") }}</th>
						<th style="text-align: center">{{ $t("executor") }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, idx) in tableContent" :key="idx">
						<td>1</td>
						<td>
							<button @click="getContractInfo(item)" class="link">
								{{ item.contractNumber }}
							</button>
						</td>
						<td>16/09/2021</td>
						<td>Продукты питания</td>
						<td>500 000 UZS</td>
						<td>400 000 UZS</td>
						<td>Тошкент вилояти Адлия бошкармаси</td>
						<td>200685329</td>
						<td>
							<button @click="$emit('openModal', item)" class="link">{{ $t("executor") }}</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<pagination class="d-block" :total="total" :perPage="query.take" @paginate="pagination($event)"></pagination>
	</div>
</template>
<script>
import AppPagination from "@/components/shared-components/AppPagination";
import Pagination from "@/components/shared-components/Pagination";

export default {
	name: "OtherContracts",
	props: {},
	components: {
		Pagination,
		AppPagination,
	},
	data() {
		return {
			query: {
				skip: 0,
				take: 10,
			},
			total: 0,
			isActive: false,
			tableContent: [],
		};
	},
	created() {
		this.load();
	},
	methods: {
		load() {
			let params = {
				...this.query,
			};
			this.$api.post("/api-client/Contract/GetAllOtherContract", params).then((response) => {
				this.total = response.data.result.total;
				this.tableContent = response.data.result.data;
			});
		},
		getContractInfo(item) {
			this.$emit("getContractInfo", item);
		},
		toggleContent() {
			this.isActive = !this.isActive;
		},
		pagination(skip) {
			this.query.Skip = skip;
			this.load();
		},
	},
};
</script>
<style lang="scss" scoped>
// .fixed-block{
// 	right: 0 !important;
// }
.fixed-block-header {
	background: #1faec1;
}
</style>
