<template>
	<div>
		<div v-if="isActive" @click="toggleContent()" class="fixed-overlay"></div>
		<div class="fixed-block pa-40 pb-0" :class="{ active: isActive }">
			<div class="form-content">
				<div class="fixed-block-header d-flex align-center justify-space-between px-30 py-20">
					<app-text
						class="font_Hsemibold mb-5"
						size="26"
						weight="600"
						line-height="18"
					>№ договора {{data.contractNumber}}
					</app-text>
					<img
						src="@/assets/images/cancel-arrow2.svg"
						@click="toggleContent()"
						class="cursor-pointer"
						width="14"
						height="14"
						alt
					/>
				</div>
				<div class="fixed-extra-block px-30 py-20">
					<app-text
						class="font_Hsemibold"
						size="18"
						weight="600"
						line-height="21"
					>Исполнитель Лота №{{ data.lotNumber }}:
					</app-text>
				</div>
				<div class="px-30 py-20">
					<table class="w-100">
						<tr>
							<td>
								<div class="d-flex flex-column text-left mb-15">
									<span class="fs-12 color-text-light mb-5">Победитель:</span>
									<span class="text-dark-14-500">{{ data.provider | translate }}</span>
								</div>
							</td>
							<td>
								<div class="d-flex flex-column text-left mb-15">
									<span class="fs-12 color-text-light mb-5">Учредитель:</span>
									<span class="text-dark-14-500"></span>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="d-flex flex-column text-left mb-15">
									<span class="fs-12 color-text-light mb-5">ИНН победителя:</span>
									<span class="text-dark-14-500">{{ data.providerInn }}</span>
								</div>
							</td>
							<td>
								<div class="d-flex flex-column text-left mb-15">
									<span class="fs-12 color-text-light mb-5">Бенефициар:</span>
									<span class="text-dark-14-500"></span>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="d-flex flex-column text-left mb-15">
									<span class="fs-12 color-text-light mb-5">Метод оценки:</span>
									<span class="text-dark-14-500"></span>
								</div>
							</td>
							<td>
								<div class="d-flex flex-column text-left mb-15">
									<span class="fs-12 color-text-light mb-5">Уд. вес:</span>
									<span class="text-dark-14-500"></span>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="d-flex flex-column text-left mb-15">
									<span class="fs-12 color-text-light mb-5">Дата заключения договора:</span>
									<span class="text-dark-14-500">{{ data.contractDate | moment('DD.MM.YYYY') }}</span>
								</div>
							</td>
							<td>
								<div class="d-flex flex-column text-left mb-15">
									<span class="fs-12 color-text-light mb-5">Адрес исполнителя:</span>
									<span class="text-dark-14-500">{{ data.providerAddress }}</span>
								</div>
							</td>
						</tr>
					</table>
				</div>
				<div class="fixed-extra-block px-30 py-20">
					<h5 class="font_Hsemibold fs-18 fw-600">Подробное описание товара (работы, услуги):</h5>
				</div>
				<div class="d-flex px-30 py-20" v-for="(info, idx) in productsInfo" :key="idx">
					<app-text class="font_Hsemibold" size="16" weight="500" line-height="18">
						{{ idx + 1 }}.
					</app-text>
					<div class="d-flex flex-column ml-10">
						<app-text class="font_Hsemibold mb-10" size="16" weight="500" line-height="19">
							{{ info.productName }}
						</app-text>
						<app-text class=" mb-10" size="16" weight="500" line-height="19">
							{{ info.productInfo }}
						</app-text>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AppText from "@/components/shared-components/AppText";

export default {
	name: "fixed-block",
	components: {
		AppText,
	},
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: {}
		},
		productsInfo: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {};
	},
	methods: {
		toggleContent() {
			this.$emit('closeFixedBlock', false)
		}
	}
};
</script>

<style lang="scss" scoped>
</style>