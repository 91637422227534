<template>
	<div class="mt-20">
		<div class="table-block" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<table>
				<thead>
					<tr>
						<th>№</th>
						<th style="max-width: 100px">{{ $t("contractNo") }}</th>
						<th style="max-width: 250px">{{ $t("dealDate") }}</th>
						<th>{{ $t("name") }}</th>
						<th>{{ $t("sourceFunded") }}</th>
						<th class="text-center">{{ $t("contractAmount") }}</th>
						<th class="text-center">{{ $t("customer") }}</th>
						<th class="text-center">{{ $t("customerInn") }}</th>
						<th class="text-center">{{ $t("executor") }}</th>
						<th class="text-center">{{ $t("periodOfExecution") }}</th>
						<th class="text-center">{{ $t("protocols") }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, idx) in data" :key="idx">
						<td>{{ idx + 1 + query.skip }}</td>
						<td>
							<button @click="getContractInfo(item)" class="link">
								{{ item.contractNumber }}
							</button>
						</td>
						<td>{{ item.dealTime | moment("DD/MM/YYYY") }}</td>
						<td>{{ item.lotName | translate }}</td>
						<td></td>
						<td>{{ item.contractSum | price }}</td>
						<td>{{ item.customer | translate }}</td>
						<td>{{ item.customerInn }}</td>
						<td>
							<button @click="$emit('openModal', item)" class="link">Исполнитель</button>
						</td>
						<td></td>
						<td></td>
					</tr>
				</tbody>
			</table>
		</div>

		<pagination class="d-block" :total="total" :perPage="query.take" @paginate="pagination($event)"></pagination>
	</div>
</template>
<script>
import FixedBlock from "@/components/pages/selection/contracts/FixedBlock";
import AppPagination from "@/components/shared-components/AppPagination";
import Pagination from "@/components/shared-components/Pagination";

export default {
	name: "TenderContract",
	props: {
		tenderType: {
			type: Number,
			required: true,
		},
	},
	components: {
		AppPagination,
		Pagination,
		FixedBlock,
	},
	data() {
		return {
			data: [],
			query: {
				tenderType: 2,
				skip: 0,
				take: 10,
			},
			total: 0,
			isActive: false,
		};
	},
	created() {
		this.query.tenderType = this.tenderType;
	},
	mounted() {
		this.load();
	},
	methods: {
		load() {
			let params = {
				...this.query,
			};
			this.$api.post("/api-client/Contract/GetAllTenderContract", params).then(
				(response) => {
					const res = response.data.result;
					this.data = res.data.map((item) => {
						item.files = this.convertFileData(item.attachContract);
						return item;
					});
					this.total = res.total;
				},
				(error) => console.log(error)
			);
		},
		convertFileData(file) {
			let files = file.split(";");
			const resultFiles = [];

			for (let i = 0; i < files.length; i++) {
				let fileName = files[i].split("/");
				resultFiles.push({
					url: process.env.VUE_APP_BASE_URL_DOMAIN + files[i],
					name: fileName[files.length - 1].substr(0, 10),
				});
			}

			return resultFiles;
		},
		getContractInfo(item) {
			this.$emit("getContractInfo", { item, contractType: 4 });
		},
		toggleContent() {
			this.isActive = !this.isActive;
		},
		pagination(skip) {
			this.query.skip = skip;
			this.load();
		},
	},
};
</script>
