<template>
	<!-- <main class="page-container container">
		<header class="page__header" :class="[isMobileMedium ? 'flex-wrap' : '', isMobileSmall ? 'mb-10' : 'mb-25']">
			<app-text
				tag="h1"
				size="26"
				line-height="34"
				weight="600"
				:class="[isMobileMedium ? 'w-100' : '', isMobileMedium ? 'mb-20' : '']"
				class="mr-30"
				>{{ $t("unifiedRegisterOfTheContract") }}
			</app-text>
		</header>

		<div class="section__wrapper" :class="isMobileSmall ? 'mb-40' : 'mb-80'">
			<app-content-card class="custom-tab-component" padding-x="0" padding-y="0" :radius="[8, 8, 0, 0]">
				<app-tabs v-model="selectedTab" :list="tabItems" />
			</app-content-card>

			<direct-contracts
				v-if="selectedTab === 'directContracts'"
				@openModal="showModalData"
				@getContractInfo="showContractInfo"
			></direct-contracts>

			<other-contracts
				v-if="selectedTab === 'otherContracts'"
				@openModal="showModalData"
				@getContractInfo="showContractInfo"
			></other-contracts>

			<auction-contracts v-if="selectedTab === 'auction'" @getContractInfo="showContractInfo"></auction-contracts>

			<shop-contracts
				v-if="selectedTab === 'shop'"
				@openModal="showModalData"
				@getContractInfo="showContractInfo"
			></shop-contracts>

			<selection-contract
				v-if="selectedTab === 'selection'"
				:tender-type="2"
				@openModal="showModalData"
				@getContractInfo="showContractInfo"
			></selection-contract>

			<tender-contract
				v-if="selectedTab === 'tender'"
				:tender-type="1"
				@openModal="showModalData"
				@getContractInfo="showContractInfo"
			></tender-contract>

			<AppModal v-if="modalVisible" v-model="modalVisible" :closeButton="false" max-width="540">
				<modal-content @close="closeModal" :data="contractInfo"></modal-content>
			</AppModal>

			<fixed-block
				:isActive="isActiveFixedBlock"
				@closeFixedBlock="toggleFixedBlock"
				:data="contractInfo"
				:productsInfo="productsInfo"
			></fixed-block>
		</div>
	</main> -->
	<div><Developing /></div>
</template>

<script>
import AppText from "@/components/shared-components/AppText";
import AppContentCard from "@/components/shared-components/AppContentCard";
import AppTabs from "@/components/shared-components/AppTabs";
import AppModal from "@/components/shared-components/AppModal";
import AppButton from "@/components/shared-components/AppButton";

import DirectContracts from "@/components/pages/selection/contracts/DirectContracts";
import AuctionContracts from "@/components/pages/selection/contracts/AuctionContract";
import ShopContracts from "@/components/pages/selection/contracts/ShopContracts";
import SelectionContract from "@/components/pages/selection/contracts/SelectionContract";
import TenderContract from "@/components/pages/selection/contracts/TenderContract";
import ModalContent from "@/components/pages/selection/contracts/ModalContent";
import FixedBlock from "@/components/pages/selection/contracts/FixedBlock";
import OtherContracts from "@/components/pages/selection/contracts/OtherContracts";
import Developing from "../../developing/index.vue";

export default {
	name: "contract-index",
	components: {
		Developing,
		FixedBlock,
		AppText,
		AppContentCard,
		AppTabs,
		AppModal,
		AppButton,
		DirectContracts,
		AuctionContracts,
		ShopContracts,
		SelectionContract,
		TenderContract,
		ModalContent,
		OtherContracts,
	},
	data() {
		return {
			modalVisible: false,
			selectedTab: "shop",
			tabItems: [
				{
					name: this.$i18n.t("shop").toUpperCase(),
					value: "shop",
				},
				{
					name: this.$i18n.t("directContractsUpperCase"),
					value: "directContracts",
				},
				{
					name: this.$i18n.t("otherTreatiesUpperCase"),
					value: "otherContracts",
				},
				{
					name: this.$i18n.t("auctionUpperCase"),
					value: "auction",
				},
				{
					name: this.$i18n.t("selectionUpperCase"),
					value: "selection",
				},
				{
					name: this.$i18n.t("tenderUpperCase"),
					value: "tender",
				},
			],
			isActiveFixedBlock: false,
			contractInfo: {},
			productsInfo: [],
		};
	},
	methods: {
		showModalData(data) {
			this.contractInfo = data;
			this.modalVisible = true;
		},
		closeModal() {
			console.log("Closing");
			this.contractInfo = {};
			this.modalVisible = false;
		},
		showContractInfo(item) {
			this.contractInfo = item.item;
			let params = {
				contractId: item.item.id,
				contractType: item.contractType,
			};
			this.$api
				.get(`/api-client/Contract/GetContractProductInfo`, {
					params,
				})
				.then((response) => {
					console.log(response.data.result);
					this.productsInfo = response.data.result;
				})
				.finally(() => {
					this.isActiveFixedBlock = true;
				});
		},
		toggleFixedBlock() {
			this.isActiveFixedBlock = false;
		},
		closeModal() {
			this.modalVisible = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.normative-acts {
	&__item {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.file-size {
	white-space: nowrap;
}

.app-tabs__item {
	font-size: 12px;
}
</style>
