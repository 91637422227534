<template>
	<div class="mt-20">
		<div class="table-block" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<table>
				<thead>
					<tr>
						<th>№</th>
						<th width="100">{{ $t("contractNumber") }}</th>
						<th width="250">{{ $t("dealDate") }}</th>
						<th>{{ $t("name") }}</th>
						<th>{{ $t("startingPrice") }}</th>
						<th class="text-center">{{ $t("amountOfDeal") }}</th>
						<th class="text-center">{{ $t("customer") }}</th>
						<th class="text-center">{{ $t("customerInn") }}</th>
						<th class="text-center">{{ $t("executor") }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, idx) in tableContent" :key="idx">
						<td>{{ idx + 1 }}</td>
						<td>
							<button @click="getContractInfo(item)" class="link">
								{{ item.contractNumber }}
							</button>
						</td>
						<td>{{ item.dealTime | moment("DD/MM/YYYY") }}</td>
						<td>{{ item.lotName | translate }}</td>
						<td>{{ item.contractSum | price }}</td>
						<td>{{ item.customer | translate }}</td>
						<td>{{ item.customerInn }}</td>
						<td>
							<button @click="$emit('openModal', item)" class="link">Исполнитель</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<Pagination :total="this.total" :perPage="this.query.Take" @paginate="pagination($event)"></Pagination>
	</div>
</template>
<script>
import AppPagination from "@/components/shared-components/AppPagination";
import Pagination from "@/components/shared-components/Pagination";
import FixedBlock from "@/components/pages/selection/contracts/FixedBlock";

export default {
	name: "AuctionContracts",
	props: {},
	components: {
		AppPagination,
		Pagination,
		FixedBlock,
	},
	data() {
		return {
			query: {
				Skip: 0,
				Take: 10,
			},
			total: 0,
			isActive: false,
			tableContent: [],
		};
	},
	created() {
		this.load();
	},
	methods: {
		load() {
			let params = {
				...this.query,
			};
			//   this.$store.commit("setLoadingStatus", true);
			this.$api.post("/api-client/Contract/GetAllAuctionContract", params).then((response) => {
				this.total = response.data.result.total;
				this.tableContent = response.data.result.data;
			});
		},
		getContractInfo(item) {
			this.$emit("getContractInfo", { item, contractType: 2 });
		},
		toggleContent() {
			this.isActive = !this.isActive;
		},
		pagination(skip) {
			this.query.Skip = skip;
			this.load();
		},
	},
};
</script>
