<template>
	<div class="modal__holder">
		<div class="label-input-group mb-30">
			<app-text size="18" weight="600">Исполнитель лота: {{ data.provider | translate }}</app-text>
		</div>
		<div class="plan-grid-container unified-grid-container">
			<div class="d-flex flex-column">
				<label class="fs-12 color-text mb-5"> Победитель:</label>
				<span class="fs-14 color-text fw-600"> {{ data.provider | translate }} </span>
			</div>
			<div class="d-flex flex-column">
				<label class="fs-12 color-text mb-5">ИНН Победителя:</label>
				<span class="fs-14 color-text fw-600"> {{ data.providerInn }}</span>
			</div>
			<div class="d-flex flex-column">
				<label class="fs-12 color-text mb-5">Дата заключения договора:</label>
				<span class="fs-14 color-text fw-600"> {{ data.contractDate | moment("DD.MM.YYYY") }}</span>
			</div>
			<div class="d-flex flex-column">
				<label class="fs-12 color-text mb-5">Учредитель:</label>
				<span class="fs-14 color-text fw-600"> </span>
			</div>
			<div class="d-flex flex-column">
				<label class="fs-12 color-text mb-5">Бенефициар:</label>
				<span class="fs-14 color-text fw-600"> </span>
			</div>
			<div class="d-flex flex-column">
				<label class="fs-12 color-text mb-5">Адрес исполнителя:</label>
				<span class="fs-14 color-text fw-600"> {{ data.providerAddress }}</span>
			</div>
		</div>
		<div class="footer_modal">
			<AppButton
				theme="gray-light"
				sides="20"
				height="40"
				weight="600"
				font-size="14"
				class="font_Hsemibold"
				@click="closeModal"
				>Закрыть
			</AppButton>
		</div>
	</div>
</template>
<script>
import AppText from "@/components/shared-components/AppText";
import AppButton from "@/components/shared-components/AppButton";
import AppModal from "@/components/shared-components/AppModal";

export default {
	name: "contract-modal-content",
	props: {
		data: {
			type: Object,
			required: true,
			default: {},
		},
	},
	components: {
		AppText,
		AppButton,
		AppModal,
	},
	data() {
		return {};
	},
	methods: {
		closeModal() {
			this.$emit("close");
			this.modalVisible = !this.modalVisible;
		},
	},
};
</script>
<style lang="scss" scoped>
.footer_modal {
	display: flex;
	justify-content: flex-end;
	padding-top: 40px;
}
</style>
