<template>
	<div class="white_block">
		<div class="developing_mode_wrapper">
			<div>
				<img src="@/assets/123606-desktop-and-smartphone-app-development.gif" alt="developing" height="350" />
			</div>
			<div class="developing_mode">
				{{ $t("inDevelopingMode") }}
			</div>
			<div class="developing_mode_button">
				<app-button>
					<a href="/">{{ $t("backToHomePage") }}</a>
				</app-button>
			</div>
		</div>
	</div>
</template>
<script>
import AppButton from "../../components/shared-components/AppButtonA.vue";
export default {
	name: "Developing",
	components: {
		AppButton,
	},
};
</script>
<style scoped lang="scss">
.developing_mode_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}
.white_block {
	display: flex;
	align-items: center;
	justify-content: center;
}
.developing_mode_button {
	button {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.2rem;
		padding: 3px 30px;
		height: auto;
	}
}
.developing_mode {
	text-align: center;
	font-size: 1.8rem;
	font-weight: 600;
}
</style>
